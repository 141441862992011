.Barchart{
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.VolumnChart {
  position: relative;
}

.VolumnChart.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}
  
  .data-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .data-table th, .data-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .data-table th {
    background-color: #f2f2f2;
  }
  
  .data-table tr:hover {
    background-color: #f9f9f9;
  }

  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination button {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination span {
    margin: 0 10px;
  }
  