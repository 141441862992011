@import url("../src/fonts/stylesheet-family-support.css");

:root {
  --background-color: #F7FBFE;
  --background-head-color: #F4F7FF;
  --text-color: #272727;
  --card-description: #272727;
  --purple: #4820E4;
  --blue: #265CFF;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-family: "Inter" ;
}

::-webkit-scrollbar {
  width: 6px;
  height: 72px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}