.session{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Session-chart-bar{
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
    display: flex;
}

.Session-table-bar{
    margin-top: 20px;
}

 .Session-chart{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  
  .search-bar input[type="text"] {
    padding: 8px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .export-button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  .successful {
    color: white;
    background-color: green;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .failed {
    color: white;
    background-color: red;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .neutral{
    color: white;
    background-color: blue;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .view-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }


  .detail-view {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .detail-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .detail-content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  
  .detail-metrics {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  /* .chat-container {
    display: flex;
    flex-direction: column;
  } */

  .chat-container {
    display: flex;
    flex-direction: column;
    /* margin: 0 auto; */
    /* padding: 20px; */
    /* max-width: 600px; */
    max-height: 50vh; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    border: 1px solid #ddd; /* Optional: adds a border to the chat area */
    border-radius: 10px; /* Optional: rounds the corners of the container */
    background-color: #f9f9f9; 
    /* background-color: #007bff; */
  }

  .chat-bubble {
    max-width: 60%;
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    position: relative;
  }
  
  .sender {
    background-color: #dcf8c6;
    align-self: flex-end;
    border-bottom-right-radius: 0;
  }
  
  .recipient {
    background-color: #ffffff;
    align-self: flex-start;
    border-bottom-left-radius: 0;
  }
  
  .message-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .timestamp {
    font-size: 0.8em;
    color: #888;
  }
  
  .feedback-indicator {
    font-size: 0.8em;
    color: red;
  }
  
  .message {
    font-size: 1em;
    color: #333;
  }
  
  .session-chart-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 400px;
  }
  
  .pie-chart{
    flex: 1.5;
    margin: 0 20px;
    /* align-items: center; */
    /* height: '400px'; */
  }
  .line-chart {
    flex: 2.5;
    margin: 0 20px;
    /* align-items: center; */
    /* height: '400px'; */
  }
  