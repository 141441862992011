    .body {
    font-size: 25px;
    color: black;
    background: #f5f5f5;
   }
   .header-content {
    border: 1px solid black;
    justify-content: space-between;
   }
   .header-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    cursor: pointer;
   }
   .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #FCF9F7;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .modal-content {
    border: 1px solid #fff;
    width: 500px;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
   }
   .modal-mainContents {
    display: flex;
    flex-direction: column;
    gap: 20px;

  }

   .exit-icon {
    cursor: pointer;
    font-size: 20px;
   }


   .modal-title {
    color: #E67817;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
   }
   .modal-image img {
    width: 8rem;
   }
   .modalText {
    text-align: justify;
   }
   .modal-button button {
    border: 1px solid #E67817;
    padding: 6px;
    background-color: #E67817;
    color: white;
    border-radius: 7px;
   }

   .form-select{
    margin-left: 20px;
   }

   .form-colon{
    margin-left: 10px;
   }

  