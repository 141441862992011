.processing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FCF9F7;
  }
  
  .processing-content {
    text-align: center;
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .processing-content h2 {
    margin-top: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .processing-content p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  
  .logout-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #E67817;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #E67817;
  }
  