

.Login {
    /* background: url('../Assets/image/Bg_graphic.png') no-repeat; */
    background-color: #FCF9F7;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    overflow: auto;
}

.Login_Card {
    width: 29%;
    padding: 40px;
    position: absolute;
    left: 50%;
    display: flex;
    min-width:440px;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 40px;
    transform: translate(-50%, 92px);
    background: #FFFF;
    box-shadow: 0px 4px 16px rgba(148, 93, 63, 0.05);
    border-radius: 0.5rem;
    max-height: 75vh;
}

.Login_Card::-webkit-scrollbar{
    width: 6px;
    height: 6px;
}

.Login_Card::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.Login_Button button {
    width: 100%;
    padding: 0.4rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    background-color: #E67817;
    border-radius: 8px;
    border-color: transparent;
    font-weight: 500;
    font-size: 16px;
    color: #FFFF;
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.atom-animation{
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.atom-animation:hover{
    transform: scale(0.98);
}

.Login_Button>button:hover{
    transform: scale(0.98);
    background-color: #d0660a;
}

.Login_Title>span {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #3F3325;
}


.Login_Input input {
    border: 2px solid #E8DFD5;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    padding: 0.6rem;
    background: #FFFF;
    line-height: 20px;
}


.Login_Input input:focus-visible {
    outline: none;
    border: 1px solid #9A938A;
    opacity: 1;
}

.login_button_disabled {
    cursor: no-drop !important;
    opacity: 0.3;
}
.input_warning {
    color: #D2281C !important;
    border: 1px solid #D2281C !important;
    opacity: 1 !important;
}

.Login_Input>#warning {
    color: #D2281C;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}


.Login_Input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Login_Input>label {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #594F42;
}

.Login_Input>.email_icon {
    display: inline-block;
    line-height: 20px;
    padding-left: 24px;
    background: url("../Assets/icon/mail.svg") no-repeat scroll 0 0 transparent;
    background-position: left;
}

.Login_Input>.password_icon {
    display: inline-block;
    line-height: 20px;
    padding-left: 24px;
    background: url("../Assets/icon/key.svg") no-repeat scroll 0 0 transparent;
    background-position: left;
}

.Login_Input>.password{
    position: relative;
    display: grid;
    align-items: center;
}

.Login_Input>.password>img{
    position: absolute;
    right: 12px;
    cursor: pointer;
}



.Login_Forgot>span {
    color: #2A78BF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
}

.Login_Forgot{
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
    cursor: pointer;
    width: fit-content;
}

.Login_Forgot:hover{
    transform: scale(0.98);
}

.Description {
    display: inline-block;
}

.Description>p {
    width: 100%;
    font-weight: 400;
    color: #594F42;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
}


.Password_conditions>p {
    font-weight: 400;
    font-size: 14px;
    color: #594F42;
}

.Password_conditions>ul {
    padding: 20px;
}

.Password_conditions>ul>li>span {
    font-weight: 400;
    font-size: 14px;
    color: #9A938A;
    line-height: 24px;
}
.spinner{
    position: absolute;
    position: absolute;
    top: 40%;
    left: 50%;
    opacity: 1;
    width: 100%;
}

