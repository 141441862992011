.Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: #f5f5f5; */
    width: 30%;
}

.Header:hover{
    cursor: pointer;
}

.Header {
    display: flex;
    justify-content: space-around;
}

.Header-item {
    cursor: pointer;
    color: grey; /* Default color */
}

.Header-item.selected {
    color: black; /* Color when selected */
}
