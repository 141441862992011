.Dashboard{
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
    /* background-color: #FCF9F7; */
}



.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header button {
    border: 1px solid #E67817;
    padding: 6px;
    width: 8rem;
    background-color: #E67817;
    color: white;
    border-radius: 7px;
    cursor: pointer;
   }

